<template>
  <div class="bg_light" v-if="title">
    <div class="item">
      <div>
        <div class="title">
          {{ content }}
        </div>
        <div class="content">{{ createdAt }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    content: {
      type: String,
      default: "内容",
    },
    createdAt: {
      type: String,
      default: "时间",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bg_light {
  background: white;
  padding: 0rem 1.2rem 0rem 1.2rem;
}
.d_flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.flex_column {
  flex-direction: column;
}
.justify_content_between {
  justify-content: space-between;
}
.align_items_start {
  align-items: flex-start;
  text-align: left;
}
.item {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0rem;
  box-sizing: border-box;
}
.title {
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
}
.content {
  font-size: 0.85rem;
  text-align: right;
  margin-top: 0.5rem;
  line-height: 1.5;
  color: var(--gray);
}
.createdAt {
  font-size: 0.85rem;
  text-align: right;
  color: var(--gray);
  margin-left: auto;
}
</style>
