<template>
  <Container theme="dark" :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <MsgContentItem
        v-for="(item, index) in msgArray"
        :key="index"
        :title="item.title"
        :content="item.sendMessage"
        :createdAt="item.createdAt"
        :id="item.id"
      ></MsgContentItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import Container from "../components/Container.vue";
import MsgContentItem from "../components/MsgContentItem.vue";
export default {
  components: {
    NoData,
    Container,
    MsgContentItem,
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    msgData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          this.title = newVal.title;
          this.changeHeader(this.title);
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("msg", ["msgData"]),
    msgArray() {
      if (!this.msgData) {
        return [];
      } else {
        let result = [];
        result.push(this.msgData);
        return result;
      }
    },
    checkResponseStatus() {
      return this.msgArray && this.msgArray.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("msg", ["getMsgById"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    ...mapActions("header", ["changeHeader"]),
  },
  created() {
    this.getMsgById(this.$route.query.id);
  },
};
</script>

<style></style>
